import { services as Service } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface InitialStateType {
  hotelSelectedTranslation: string;
  houseKeeping: any;
  minibar: Service | null;
  maintenance: Service | null;
  roomService: Service | null;
  pool: Service | null;
  customName: string;
  customTranslations: any[];
  showVoidModal: boolean;
}

const initialState: any = {
  hotelSelectedTranslation: "no",
  houseKeeping: null,
  minibar: null,
  maintenance: null,
  roomService: null,
  showVoidModal: false,
  pool: null,
  customData: {
    name: "",
    translations: [],
  },
  orderClicked: false,
};

const HotelServiceSlice = createSlice({
  name: "hotelService",
  initialState,
  reducers: {
    insertHouseKeeping: (state, action) => {
      state.houseKeeping = action.payload;
    },
    insertMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    updateSpecifiedMtValue: (state, action) => {
      state.maintenance = { ...state.maintenance, ...action.payload };
    },
    insertRoomService: (state, action) => {
      state.roomService = action.payload;
    },
    insertMinibar: (state, action) => {
      state.minibar = action.payload;
    },
    setHotelSelectedTranslation: (state, action) => {
      state.hotelSelectedTranslation = action.payload;
    },
    insertVoidModal: (state, action) => {
      state.showVoidModal = action.payload;
    },
    insertPool: (state, action) => {
      state.pool = action.payload;
    },
    insertCustomData: (state, action) => {
      state.customData = action.payload;
    },
    insertOrderClicked: (state, action) => {
      state.orderClicked = action.payload;
    },
  },
});

export const {
  insertHouseKeeping,
  insertMinibar,
  insertMaintenance,
  insertRoomService,
  setHotelSelectedTranslation,
  insertVoidModal,
  insertPool,
  insertCustomData,
  insertOrderClicked,
  updateSpecifiedMtValue,
} = HotelServiceSlice.actions;

export const getRoomServiceData = (state: RootState) =>
  state.hotelService.roomService;
export const getMaintenanceData = (state: RootState) =>
  state.hotelService.maintenance;
export const getHousekeepingData = (state: RootState) =>
  state.hotelService.houseKeeping;
export const getHotelSelectedTranslation = (state: RootState) =>
  state.hotelService.hotelSelectedTranslation;
export const getMinibar = (state: RootState) => state.hotelService.minibar;
export const getPool = (state: RootState) => state.hotelService.pool;

export const showVoidModalValue = (state: RootState) =>
  state.hotelService.showVoidModal;
export const getCustomData = (state: RootState) =>
  state.hotelService.customData;
export const getOrderClicked = (state: RootState) =>
  state.hotelService.orderClicked;

export default HotelServiceSlice.reducer;
