import axios from "axios";

import { getSessionItem, getStorageItem } from "../functions/localStorage";

import { config } from "../config";

const apiInstance = axios.create({
  baseURL: config.NEXT_PUBLIC_API_BASE_URL,
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${getStorageItem("accessToken")}`,
  },
});

const apiInstanceFormData = axios.create({
  baseURL: config.NEXT_PUBLIC_API_BASE_URL,
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    "content-Type": "multipart/form-data",
    Authorization: `Bearer ${getStorageItem("accessToken")}`,
  },
});

const apiNestServer = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REMINDER_SERVER_URL,
  // baseURL: "http://localhost:4000/api/",
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export { apiInstance, apiInstanceFormData, apiNestServer };
