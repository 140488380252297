import { createSlice } from "@reduxjs/toolkit";
import { orderType, serviceOrderType } from "../../lib/types";
import housekeeping from "../../pages/api/hotel/housekeeping";

export interface MenuInitialStateType {
  orders: orderType[];
  miniBarOrders: orderType[];
  houseKeepingOrders: any[];
  maintenanceOrders: any[];
  analyticMaintenanceData: any[];
  customOrders: any[];
}

const initialState: MenuInitialStateType = {
  orders: [],
  miniBarOrders: [],
  houseKeepingOrders: [],
  maintenanceOrders: [],
  analyticMaintenanceData: [],
  customOrders: [],
};

const adminOrder = createSlice({
  name: "order",
  initialState,
  reducers: {
    insertOrdersForAdmin: (state, action) => {
      state.orders = action.payload;
    },
    insertMiniBarOrders: (state, action) => {
      state.miniBarOrders = action.payload;
    },
    insertCustomOrders: (state, action) => {
      state.customOrders = action.payload;
    },
    insertHousekeepingOrdersForAdmin: (state, action) => {
      state.houseKeepingOrders = action.payload;
    },
    insertMaintenanceOrdersForAdmin: (state, action) => {
      state.maintenanceOrders = action.payload;
    },
    insertAnalyticMaintenanceData: (state, action) => {
      state.analyticMaintenanceData = action.payload;
    },
    updateHousekeepingStatus: (state, action) => {
      const newOrdersArr = state.houseKeepingOrders.map((housekeepingOrder) => {
        return housekeepingOrder.id === action.payload.id
          ? action.payload
          : housekeepingOrder;
      });
      state.houseKeepingOrders = newOrdersArr;
    },
    updateMaintenanceStatus: (state, action) => {
      const newOrdersArr = state.maintenanceOrders.map((maintenanceOrder) => {
        return maintenanceOrder.id === action.payload.id
          ? action.payload
          : maintenanceOrder;
      });
      state.maintenanceOrders = newOrdersArr;
    },
  },
});

export const {
  insertOrdersForAdmin,
  insertHousekeepingOrdersForAdmin,
  insertMaintenanceOrdersForAdmin,
  insertAnalyticMaintenanceData,
  updateHousekeepingStatus,
  updateMaintenanceStatus,
  insertMiniBarOrders,
  insertCustomOrders,
} = adminOrder.actions;

export const getAnalyticMaintenanceData = (state: any) =>
  state.adminOrder.analyticMaintenanceData;

export default adminOrder.reducer;
