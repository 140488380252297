import { createSlice } from "@reduxjs/toolkit";

export interface ReturnTabInitialStateType {
  menuTabValue: string;
  addonTabValue: number;
  cfCategoryTabValue: number;
  miniBarTabValue: number;
}

const initialState = {
  menuTabValue: "none",
  addonTabValue: 0,
  deletedTabValue: 0,
  createdMenuTabValue: 0,
  editedMenuTabValue: 0,
  cfCategoryTabValue: 0,
  cfMinibarCategoryTabValue: 0,
  miniBarTabValue: "none",
};

const returntab = createSlice({
  name: "returnTab",
  initialState,
  reducers: {
    storeCurrentMenuTabValue: (state, action) => {
      state.menuTabValue = action.payload;
    },
    storeCurrentMinibarTabValue: (state, action) => {
      state.miniBarTabValue = action.payload;
    },
    storeDeletedMenuTabValue: (state, action) => {
      state.deletedTabValue = action.payload;
    },
    storeCreatedMenuTabValue: (state, action) => {
      state.createdMenuTabValue = action.payload;
    },
    storeEditedMenuTabValue: (state, action) => {
      state.editedMenuTabValue = action.payload;
    },
    storeCurrentAddonTabValue: (state, action) => {
      state.addonTabValue = action.payload;
    },
    storeCurrentCfCategoryTabValue: (state, action) => {
      state.cfCategoryTabValue = action.payload.value;
    },
  },
});

export const {
  storeCurrentMenuTabValue,
  storeDeletedMenuTabValue,
  storeCreatedMenuTabValue,
  storeEditedMenuTabValue,
  storeCurrentAddonTabValue,
  storeCurrentCfCategoryTabValue,
  storeCurrentMinibarTabValue,
} = returntab.actions;
export const getCurrentMenuTabValue = (state: any) =>
  state.returnTab.menuTabValue;
export const getCurrentMinibarTabValue = (state: any) =>
  state.returnTab.miniBarTabValue;
export const getDeletedTabValue = (state: any) =>
  state.returnTab.deletedTabValue;
export const getCreatedMenuTabValue = (state: any) =>
  state.returnTab.createdMenuTabValue;
export const getEditedMenuTabValue = (state: any) =>
  state.returnTab.editedMenuTabValue;

export default returntab.reducer;
