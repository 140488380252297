import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { clear } from "console";
export interface initialState {
  category: any;
  orgMenuItem: any;
  categoryItemsIds: any;
  dataToEdit?: any;
  formattedUpSell: any;
  selectedUpSell?: any;
  selectedChosenItem: any;
}

const initialState: initialState = {
  category: [],
  orgMenuItem: [],
  categoryItemsIds: [],
  dataToEdit: {},
  formattedUpSell: [],
  selectedUpSell: {},
  selectedChosenItem: {},
};

const UpSell = createSlice({
  name: "upsell",
  initialState,
  reducers: {
    insertPropsData: (
      state: initialState,
      action: PayloadAction<initialState>
    ) => {
      state.category = action.payload.category;
      state.categoryItemsIds = action.payload.categoryItemsIds;
      state.orgMenuItem = action.payload.orgMenuItem;
      state.dataToEdit = action.payload.dataToEdit || {};
    },
    deletePropsData: (state: initialState) => {
      state.category = [];
      state.categoryItemsIds = [];
      state.orgMenuItem = [];
      state.dataToEdit = {};
    },
    insertFormattedMenu: (
      state: initialState,
      action: PayloadAction<initialState>
    ) => {
      state.formattedUpSell = action.payload;
    },
    insertSelectedUpSell: (state: initialState, action: PayloadAction<any>) => {
      state.selectedUpSell = action.payload;
    },
    insertSelectedChosenItem: (
      state: initialState,
      action: PayloadAction<initialState>
    ) => {
      state.selectedChosenItem = action.payload;
    },
  },
});

export const {
  insertPropsData,
  deletePropsData,
  insertFormattedMenu,
  insertSelectedUpSell,
  insertSelectedChosenItem,
} = UpSell.actions;

export const getCategory = (state: RootState) => state.upSell.category;
export const getOrgMenuItem = (state: RootState) => state.upSell.orgMenuItem;
export const getCategoryItemsIds = (state: RootState) =>
  state.upSell.categoryItemsIds;
export const getDataToEdit = (state: RootState) => state.upSell.dataToEdit;
export const getFormattedUpSell = (state: RootState) =>
  state.upSell.formattedUpSell;
export const getSelectedUpSell = (state: RootState) =>
  state.upSell.selectedUpSell;

export const selectedChosenItem = (state: RootState) =>
  state.upSell.selectedChosenItem;

export default UpSell.reducer;
