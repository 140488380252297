// //import { cache } from "react";

// export const getTranslations = async (
//   locale: string,
//   namespace = "default"
// ) => {
//   try {
//     const response = await fetch(
//       "https://hopeful-shannon3-tq6dv.dev-2.tempolabs.ai/api/translations?namespace=" +
//         namespace +
//         "&locale=" +
//         locale,
//       {
//         headers: {
//           Authorization: "Bearer trn_367w2zjowfe_i9oh4g1o4",
//           "Project-ID": "translation-project-123",
//         },

//         next: { revalidate: 3600 }, // Cache for 1 hour
//       }
//     );

//     if (!response.ok) throw new Error("Failed to fetch translations");

//     return response.json();
//   } catch (error) {
//     console.error("Translation fetch error:", error);
//     return {};
//   }
// };

// export function useTranslations(translations: any) {
//   return {
//     t: (key: string, params = {}) => {
//       if (!translations[key]) return key;

//       let text = translations[key];
//       Object.entries(params).forEach(([paramName, value]) => {
//         text = text.replace(new RegExp("{{" + paramName + "}}", "g"), value);
//       });

//       return text;
//     },
//   };
// }

// export async function getServerSideTranslations(
//   locale: string,
//   namespaces: any[]
// ) {
//   console.log("LOCALE", locale);
//   try {
//     const promises = namespaces.map((namespace) =>
//       fetch(
//         "https://hopeful-shannon3-tq6dv.dev-2.tempolabs.ai/api/translations?namespace=" +
//           namespace +
//           "&locale=" +
//           locale,
//         {
//           headers: {
//             Authorization: "Bearer trn_367w2zjowfe_i9oh4g1o4",
//             "Project-ID": "translation-project-123",
//           },
//         }
//       ).then((res) => {
//         console.log("RES", res);
//         return res;
//       })
//     );
//     console.log("PROMISES", promises);

//     const results = await Promise.all(promises);
//     console.log(
//       "RESULTS",
//       results.reduce((acc, curr) => ({ ...acc, ...curr }), {})
//     );

//     // Merge all namespace results
//     return results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
//   } catch (error) {
//     console.error("Translation fetch error:", error);
//     return {};
//   }
// }

/**
 * Translation utility functions for Next.js applications
 */

// Cache for translations to avoid unnecessary API calls
const translationCache: Record<string, Record<string, string>> = {};

/**
 * Fetch translations for server-side rendering (getStaticProps/getServerSideProps)
 */
export async function getTranslations(locale = "en", namespace = "default") {
  const cacheKey = `${locale}:${namespace}`;

  // Return cached translations if available
  if (translationCache[cacheKey]) {
    console.log("transla", cacheKey);
    return translationCache[cacheKey];
  }
  console.log("HELLO");

  try {
    const response = await fetch(
      // `https://hopeful-shannon3-tq6dv.dev-2.tempolabs.ai/api/translations?namespace=default&locale=${locale}`,
      `https://translate-app-fawn.vercel.app/api/translations/${locale}/default`,
      {
        headers: {
          Authorization: "Bearer trn_1fu8ifb3dre_ebvenyyvegg",
          "Project-ID": "translation-project-123",
        },
      }
    );

    if (!response.ok)
      throw new Error(`Failed to fetch translations: ${response.status}`);

    const data = await response.json();

    // Store in cache
    translationCache[cacheKey] = data;

    return data;
  } catch (error) {
    console.error("Translation fetch error:", error);
    return {};
  }
}

/**
 * For getServerSideProps usage - fetch multiple namespaces
 */
export async function getServerSideTranslations(
  locale = "en",
  namespaces = ["default"]
) {
  try {
    const promises = namespaces.map((namespace) =>
      getTranslations(locale, namespace)
    );

    const results = await Promise.all(promises);

    // Merge all namespace results
    return results.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  } catch (error) {
    console.error("Translation fetch error:", error);
    return {};
  }
}

/**
 * Client-side hook for using translations
 * Note: This is kept for backward compatibility, but useTranslation from context is preferred
 */
export function useTranslations(initialData: Record<string, string> = {}) {
  const t = (key: string, params: Record<string, string> = {}) => {
    if (!initialData[key]) return key;

    let text = initialData[key];
    Object.entries(params).forEach(([paramName, value]) => {
      text = text.replace(new RegExp(`{{${paramName}}}`, "g"), value);
    });

    return text;
  };

  return { t, translations: initialData };
}
