import { services as Service } from "@prisma/client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  popUpOpen: boolean;
  orderServiceState: "" | "rs" | "minibar";
  languageSelector: boolean;
}

const initialState: initialStateType = {
  popUpOpen: true,
  orderServiceState: "",
  languageSelector: false,
};

const CfPopUpReducer = createSlice({
  name: "CfPopUpReducer",
  initialState,
  reducers: {
    insertCfPopUpStatus: (
      state: initialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.popUpOpen = action.payload;
    },
    setOrderServiceState: (
      state: initialStateType,
      action: PayloadAction<"" | "rs" | "minibar">
    ) => {
      state.orderServiceState = action.payload;
    },
    setOpenLanguageSelector: (
      state: initialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.languageSelector = action.payload;
    },
  },
});

export const {
  insertCfPopUpStatus,
  setOrderServiceState,
  setOpenLanguageSelector,
} = CfPopUpReducer.actions;

export const getPopUpStatus = (state: RootState) =>
  state.CfPopUpReducer.popUpOpen;

export const getOrderServiceState = (state: RootState) =>
  state.CfPopUpReducer.orderServiceState;

export const getLanguageSelectorStatus = (state: RootState) =>
  state.CfPopUpReducer.languageSelector;

export default CfPopUpReducer.reducer;
